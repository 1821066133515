@use 'styles/theme/theme' as theme;

.artistBanner {
  background: theme.$orangeLight;
  display: flex;
  align-items: flex-end;
  padding: 38px 48px;
  position: relative;
  cursor: pointer;

  &__textWrapper {
    z-index: 1;
    display: flex;
    align-items: flex-end;
    &_primaryText {
      display: flex;
      .otherText {
        color: theme.$black;
        font-family: "Playfair Display";
        font-size: 32px;
      }
      .title {
        color: theme.$black;
        font-family: "Playfair Display";
        font-size: 32px;
        span {
          vertical-align: super;
          font-size: 15px;
          margin-left: -5px;
        }
      }
    }

    &_secondaryText {
      .subTitle {
        color: theme.$black;
        text-transform: uppercase;
        letter-spacing: 3px;
      }
    }
  }

  &__btn {
    padding: 6px 12px 6px 16px;
    color: theme.$primaryGrey;
    margin-left: auto;
    align-self: center;
    svg {
      fill: theme.$primaryGrey !important;

      @media screen and (max-width: 900px){
        width: 14px !important;
        height: 14px !important;
      }
    }

    @media screen and (max-width: 900px) {
      font-size: 12px;
      padding: 6px 0px 6px 16px;
    }
  }

  &:hover > &__btn {
    background: theme.$blue;
    text-decoration: none;
    color: theme.$white;
    svg {
      fill: theme.$white !important;
    }

    @media screen and (max-width: 900px) {
      background: transparent;
      color: theme.$blue;
      svg {
        fill: theme.$blue !important;
      }
    }
  }
}

.bannerOne {
  .artistBanner {
    &__textWrapper {
      column-gap: 28px;
      &_secondaryText {
        .subTitle {
          color: theme.$orange;
        }
      }
    }
  }
}

.bannerTwo, .bannerThree {
  .artistBanner {
    &__textWrapper {
      column-gap: 28px;
      flex-wrap: wrap;
      &_primaryText {
        .otherText {
          margin-right: 8px;
        } 
      }
      &_secondaryText {
        .subTitle {
          color: theme.$orange;
        }
      }
    }
  }
}

.bannerFour {
  align-items: center;
  padding: 38px 36px;
  .artistBanner {
    &__textWrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      &_primaryText {
        .title {
          color: theme.$orange;
        }
        .otherText {
          margin-right: 8px;
        } 
      }
      &_secondaryText {
        .subTitle {
          color: theme.$orange;
          display: block;
        }
      }
    }
  }
}

.bannerFive {
  align-items: center;
  background: theme.$grey13;
  .artistBanner {
    &__textWrapper {
      gap: 12px;
      &_primaryText {
        .title {
          color: theme.$white;
        }
        .otherText {
          margin-right: 8px;
          color: theme.$orange;
        } 
      }
      &_secondaryText {
        margin-left: 20px;
        .subTitle {
          color: theme.$orange;
          display: block;
        }
      }
    }

    &__btn {
      background: theme.$blue;
      text-decoration: none;
      color: theme.$white;
      svg {
        fill: theme.$white !important;
      }
  
      @media screen and (max-width: 900px) {
        background: transparent;
        color: theme.$blue;
        svg {
          fill: theme.$blue !important;
        }
      }
    }
  }
}

.bannerSix {
  align-items: center;
  padding: 24px 48px;
  .artistBanner {
    &__textWrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      &_primaryText {
        .title {
          text-transform: uppercase;
          font-family: 'Roboto';
          color: theme.$orange;
        }
        .otherText {
          text-transform: uppercase;
          margin-right: 8px;
          font-family: 'Roboto';
        } 
      }
      &_secondaryText {
        .subTitle {
          color: theme.$grey12;
          display: block;
        }
      }
    }
  }
}

.bgBlurredShade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 489.277px;
  transform: translate(0%, 10%);
  background: theme.$white;
  filter: blur(69.1500015258789px);
  width: 500px;
}

.bgBlurredShadeMobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 489.277px;
  transform: translate(0%, 10%);
  background: theme.$white;
  filter: blur(69.1500015258789px);
  width: 500px;
  @media screen and (max-width: 600px){
    width: 100%;
    filter: blur(120px);
  }
}

.castingBanner {
  position: relative;
  background: theme.$orangeLight;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
  padding: 28px 48px;
  cursor: pointer;
  &__leftBlock {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 32px;
    margin-left: 10px;
    .title {
      font-size: 48px;
      font-family: "Playfair Display";
      color: theme.$black;
    }
    &_subTextBlock {
      display: flex;
      flex-direction: column;
      .subTitle {
        font-size: 24px;
        color: theme.$orange;
      }
    }
  }
  &__rightBlock {
    display: flex;
    align-items: center;
    gap: 24px;
    z-index: 1;
    &_listText {
      text-transform: uppercase;
      font-weight: theme.$font-weight-medium;
      color: theme.$grey12;
    }
    &_btn {
      padding: 6px 12px 6px 16px;
      color: theme.$primaryGrey;
      margin-left: auto;
      svg {
        fill: theme.$primaryGrey !important;
  
        @media screen and (max-width: 900px){
          width: 14px !important;
          height: 14px !important;
        }
      }
  
      @media screen and (max-width: 900px) {
        font-size: 12px;
        padding: 6px 0px 6px 16px;
      }
    }
  }

  &:hover {
    .castingBanner__rightBlock_btn {
      background: theme.$blue;
      text-decoration: none;
      color: theme.$white;
      svg {
        fill: theme.$white !important;
      }
  
      @media screen and (max-width: 900px) {
        background: transparent;
        color: theme.$blue;
        svg {
          fill: theme.$blue !important;
        }
      }
    }
  }
}

.castingBannerSmall {
  position: relative;
  width: 610px;
  background: theme.$orangeLight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 17px 12px 17px;
  overflow: hidden;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  &__btn {
    padding: 6px 12px 6px 16px;
    color: theme.$primaryGrey;
    svg {
      fill: theme.$primaryGrey !important;
    }
  }

  &:hover > &__btn {
    background: theme.$blue;
    text-decoration: none;
    color: theme.$white;
    svg {
      fill: theme.$white !important;
    }

    @media screen and (max-width: 900px) {
      background: transparent;
      color: theme.$blue;
      svg {
        fill: theme.$blue !important;
      }
    }
  }

  &__textArea {
    z-index: 1;
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 600px) {
      gap: 8px;
      flex-direction: column;
      align-items: flex-start;
    }
    &_mainText {
      font-size: 36px;
      font-family: "Playfair Display";
      color: theme.$primaryGrey;
      @media screen and (max-width: 600px) {
        font-size: 28px;
      }
    }
    &_subText {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 18px;
        color: theme.$orange;
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      .subTitle {
        margin-top: 6px;
        color: theme.$black;
        font-size: 14px;
        font-weight: theme.$font-weight-medium;
        @media screen and (max-width: 600px) {
          font-size: 10px;
          margin-top: 4px;
        }
      }
      .workTypes {
        margin-top: 12px;
        color: theme.$grey12;
        font-size: 10px;
        font-weight: theme.$font-weight-medium;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        @media screen and (max-width: 600px) {
          font-size: 8px;
          margin-top: 8px;
        }
      }
    }
  }
}

.artistBannerSmall {
  max-width: 610px;
  background: theme.$orangeLight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 17px 27px 29px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &__textArea {
    z-index: 1;
    display: flex;
    flex-direction: column;
    &_mainText {
      .title {
        color: theme.$black;
        font-family: "Playfair Display";
        @media screen and (max-width: 600px) {
          font-size: 20px !important;
        }
        span {
          vertical-align: super;
          font-size: 12px;
          margin-left: -5px;
        }
      }
      .otherText {
        color: theme.$black;
        margin-right: 8px;
        font-family: "Playfair Display";
        @media screen and (max-width: 600px) {
          font-size: 20px !important;
        }
      }
    }
    .disclaimer {
      color: theme.$grey14;
      margin-top: 6px;
    }
    .subtitle {
      margin-top: 15px;
      color: theme.$orange;
      font-size: 12px;
      font-weight: theme.$font-weight-medium;
      letter-spacing: 3px;
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 10px;
      }
    }
  }

  &__btn {
    padding: 6px 12px 6px 16px;
    color: theme.$primaryGrey;
    @media screen and (max-width: 600px) {
      font-size: 12px !important;
    }
    svg {
      fill: theme.$primaryGrey !important;
      width: 16px !important;
      height: 16px !important;
    }
  }

  &:hover > &__btn {
    background: theme.$blue;
    text-decoration: none;
    color: theme.$white;
    svg {
      fill: theme.$white !important;
    }

    @media screen and (max-width: 900px) {
      background: transparent;
      color: theme.$blue;
      svg {
        fill: theme.$blue !important;
      }
    }
  }

  &.bannerOne {
    .artistBannerSmall {
      &__textArea {
        &_mainText {
          .title {
            font-size: 32px;
          }
        }
      }
    }
  }

  &.bannerTwo, &.bannerThree {
    .artistBannerSmall {
      &__textArea {
        &_mainText {
          display: flex;
          flex-wrap: wrap;
          .title, .otherText {
            font-size: 28px;
          }
        }
      }
    }
  }

  &.bannerThree {
    .artistBannerSmall {
      &__textArea {
        &_mainText {
          .otherText {
            color: theme.$orange;
          }
        }
      }
    }
  }

  &.bannerFour {
    padding: 27px 8px 27px 29px;
    .artistBannerSmall {
      &__textArea {
        &_mainText {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 24px;
            color: theme.$black;
          }
          .otherText {
            font-size: 24px;
            color: theme.$orange;
          }
        }
        .subtitle {
          margin-top: 13px;
        }
      }
    }
  }

  &.bannerFive {
    background: theme.$grey13;
    .artistBannerSmall {
      &__textArea {
        &_mainText {
          .title {
            font-size: 24px;
            color: theme.$white;
          }
          .otherText {
            font-size: 24px;
            color: theme.$orange;
          }
        }
      }
      &__btn {
        color: theme.$white;
        svg {
          fill: theme.$white !important;
        }
      }
    }
  }

  &.bannerSix {
    .artistBannerSmall {
      &__textArea {
        &_mainText {
          display: flex;
          flex-direction: column;
          .title {
            font-family: Roboto;
            font-size: 24px;
            text-transform: uppercase;
          }
          .otherText {
            font-size: 24px;
            text-transform: uppercase;
            font-family: Roboto;
            color: theme.$orange;
          }
        }
        .subtitle {
          margin-top: 13px;
        }
      }
    }
  }

}

.organizationBanner {
  position: relative;
  background: theme.$orangeLight;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  cursor: pointer;
  &__leftBlock {
    z-index: 1;
    margin-left: 10px;
    .title {
      font-size: 28px;
      font-family: "Playfair Display";
      color: theme.$black;
    }
    .subTitle {
      font-size: 12px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }
  &__rightBlock {
    z-index: 1;
    &_btn {
      padding: 6px 12px 6px 16px;
      color: theme.$primaryGrey;
      margin-left: auto;
      svg {
        fill: theme.$primaryGrey !important;
  
        @media screen and (max-width: 900px){
          width: 14px !important;
          height: 14px !important;
        }
      }
  
      @media screen and (max-width: 900px) {
        font-size: 12px;
        padding: 6px 0px 6px 16px;
      }
    }
  }

  &:hover {
    .organizationBanner__rightBlock_btn {
      background: theme.$blue;
      text-decoration: none;
      color: theme.$white;
      svg {
        fill: theme.$white !important;
      }
  
      @media screen and (max-width: 900px) {
        background: transparent;
        color: theme.$blue;
        svg {
          fill: theme.$blue !important;
        }
      }
    }
  }
}